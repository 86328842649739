<template>
  <div>

    <v-text-field @click:append="clicked" clearable outlined label="Append" append-icon="mdi-plus"></v-text-field>
    <v-list subheader two-line flat>

      <v-list-item-group v-model="settings" multiple>

        <v-list-item v-for="item in items">

          <template v-slot:default="{ active, }">

            <v-list-item-action>
              <v-checkbox :input-value="active" color="primary"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>

          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>

export default {
  methods: {
    clicked() {
      alert("clicked")
    }
  },
  name: 'Home',

  components: {
  },
  data: () => ({
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/' },
      { title: 'Photos', icon: 'mdi-image', to: '/photos' },
      { title: 'About', icon: 'mdi-help-box', to: '/about' },
    ],
  }),
}
</script>
