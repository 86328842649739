import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTextField,{attrs:{"clearable":"","outlined":"","label":"Append","append-icon":"mdi-plus"},on:{"click:append":_vm.clicked}}),_c(VList,{attrs:{"subheader":"","two-line":"","flat":""}},[_c(VListItemGroup,{attrs:{"multiple":""},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},_vm._l((_vm.items),function(item){return _c(VListItem,{scopedSlots:_vm._u([{key:"default",fn:function({ active, }){return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]}}],null,true)})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }