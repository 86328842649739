import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VNavigationDrawer,{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6"},[_vm._v(" Application ")]),_c(VListItemSubtitle,[_vm._v(" subtext ")])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":"","to":item.to}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1),_c(VAppBar,{attrs:{"app":"","color":"#fcb69f","dark":"","shrink-on-scroll":"","src":"https://picsum.photos/1920/1080?random","scroll-target":"#scrolling-techniques-2"},scopedSlots:_vm._u([{key:"img",fn:function( { props }){return [_c(VImg,_vm._b({attrs:{"gradient":"to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"}},'v-img',props,false))]}}])},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VAppBarTitle,[_vm._v("Title")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-heart")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)],1),_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }